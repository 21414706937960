<!--
 * @Descripttion: 收货地址页面组件
 * @Author: congz
 * @Date: 2020-07-11 15:12:07
 * @LastEditors: congz
 * @LastEditTime: 2020-08-05 15:40:11
--> 

<template>
  <div class="address" id="address" name="address">
    <div class="address-layout">
      <el-row :gutter="10">
        <div>
          <CenterMenu></CenterMenu>
        </div>
        <el-col :span="20">
          <div class="address-content">
            <div class="extra" style="background: #f5f5f5;"></div>
            <div class="address-title">
              <p>收货地址</p>
            </div>
            <div class="address-body">
              <ul>
                <router-link to>
                  <li class="add-address" @click="add">
                    <i class="el-icon-circle-plus-outline"></i>
                    <p>添加新地址</p>
                  </li>
                </router-link>
                <router-link to>
                  <li
                    :class="item.id == confirmAddress ? 'in-section' : ''"
                    v-for="(item,index) in address"
                    :key="item.id"
                    @mouseenter="mouseEnter(item)"
                    @mouseleave="mouseLeave(item)"
                  >
					<h2>{{item.name}}</h2>
					<p class="phone">{{item.content}}</p>
					<p style="margin-top:10px">{{item.province}}-{{item.city}}-{{item.area}}</p>
					<p class="address">{{item.address}}</p>
                    <div class="operate" v-show="item.seen">
                      <span @click="edit(item, index)">修改</span>
                      <span @click="deleteDialog(item.id,index)">删除</span>
                    </div>
                  </li>
                </router-link>
              </ul>
            </div>
          </div>
          <!-- 新建收货地址弹出框 -->
          <el-dialog title="新建收货地址" :visible.sync="addVisible" width="35%">
			<el-form ref="form" :model="form" :rules="rules" label-width="90px">
				<el-form-item label="联系人名称" prop="name">
					<el-input v-model="form.name"></el-input>
				</el-form-item>
				<el-form-item label="联系手机号" prop="content">
					<el-input v-model="form.content"></el-input>
				</el-form-item>
				<el-form-item label="省市区" prop="areaArr">
					<el-cascader v-model="form.areaArr" size="medium" placeholder="请选择省市区" :options="arData" style="width:100%" filterable clearable />
				</el-form-item>
				<el-form-item label="详细地址" prop="address">
					<el-input type="textarea" rows="5" v-model="form.address"></el-input>
				</el-form-item>
				<el-form-item label="是否为默认">
					<el-switch v-model="form.is_default" active-value="1" inactive-value="0" active-text="是" inactive-text="否" />
				</el-form-item>
			</el-form>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="postEdit">确 定</el-button>
              <el-button @click="addVisible = false">取 消</el-button>
            </span>
          </el-dialog>
          <!-- 新建收货地址弹出框END -->
          <!-- 修改收货地址弹出框 -->
          <el-dialog title="修改收货地址" :visible.sync="editVisible" width="35%">
			<el-form ref="form" :model="form" :rules="rules" label-width="90px">
				<el-form-item label="联系人名称" prop="name">
					<el-input v-model="form.name"></el-input>
				</el-form-item>
				<el-form-item label="联系手机号" prop="content">
					<el-input v-model="form.content"></el-input>
				</el-form-item>
				<el-form-item label="省市区" prop="areaArr">
					<el-cascader v-model="form.areaArr" size="medium" placeholder="请选择省市区" :options="arData" style="width:100%" filterable clearable />
				</el-form-item>
				<el-form-item label="详细地址" prop="address">
					<el-input type="textarea" rows="5" v-model="form.address"></el-input>
				</el-form-item>
				<el-form-item label="是否为默认">
					<el-switch v-model="form.is_default" active-value="1" inactive-value="0" active-text="是" inactive-text="否" />
				</el-form-item>
			</el-form>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="saveEdit">确 定</el-button>
              <el-button @click="editVisible = false">取 消</el-button>
            </span>
          </el-dialog>
          <!-- 修改收货地址弹出框END -->
          <!-- 删除收货地址弹出框 -->
          <el-dialog title="提示" :visible.sync="deleteVisible" width="30%" center>
            <div class="delete-dialog">
              <span>确认删除该地址吗？</span>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="deleteAddress">确 定</el-button>
              <el-button @click="deleteVisible = false">取 消</el-button>
            </span>
          </el-dialog>
          <!-- 删除收货地址弹出框END -->
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import CenterMenu from '../components/CenterMenu'
import * as addressesAPI from '@/api/addresses'
import arData from '@/utils/address.js'
export default {
  name: 'Details',
  data() {
	var validatePhone = (rule, value, callback) => {
		if (value === '') {
			callback(new Error('请输入手机号'))
		} else if (value.length !== 11) {
			callback(new Error('手机号长度应为11位'))
		}
		callback()
	}
	var validateName = (rule, value, callback) => {
	if (value === '') {
		callback(new Error('请输入联系人'))
	} else if (value.length > 16) {
		callback(new Error('联系人内容过长'))
	}
		callback()
	}
	var validateAreaArr = (rule, value, callback) => {
	if (value.lenght === 0) {
		callback(new Error('请选择省市区'))
	} else if (value.length < 3) {
		callback(new Error('请选择省市区'))
	}
		callback()
	}
	var validateAddress = (rule, value, callback) => {
	if (value === '') {
		callback(new Error('请填写详细地址'))
	} else if (value.length > 191) {
		callback(new Error('详细地址内容过长'))
	}
		callback()
	}
    return {
      address: [],
      addVisible: false,
      editVisible: false,
      deleteVisible: false,
      confirmAddress: 0, // 选择的地址id
      addressID: 0,
      addressIndex: 0,
		arData: arData,
		rules: {
			name: [{ validator: validateName, trigger: 'blur' }],
			content: [{ validator: validatePhone, trigger: 'blur' }],
			areaArr: [{ validator: validateAreaArr, trigger: 'blur' }],
			address: [{ validator: validateAddress, trigger: 'blur' }]
		},
		form: {
			id: 0,
			name: '',
			content: '',
			address: '',
			is_default: 0,
			areaArr: [],
			province: '',
			city: '',
			area: ''
		},
		tempForm: {
			id: 0,
			name: '',
			content: '',
			address: '',
			is_default: 0,
			areaArr: [],
			province: '',
			city: '',
			area: ''
		}
    }
  },
  created() {
    this.getAddress()
  },
  methods: {
    getAddress() {
      addressesAPI
        .showAddresses()
        .then(res => {
          if (res.status_code === 200) {
            this.address = res.data.map(item => {
				return {
					id: item.id,
					name: item.name,
					content: item.content,
					address: item.address,
					is_default: item.is_default + '',
					areaArr: [item.province, item.city, item.area],
					province: item.province,
					city: item.city,
					area: item.area
				}
			})
          } else if (res.status === 20001) {
            //token过期，需要重新登录
            this.loginExpired(res.msg)
          } else {
            this.notifyError('获取收货地址失败', res.msg)
          }
        })
        .catch(err => {
          this.notifyError('获取收货地址失败', err)
        })
    },
    mouseEnter(item) {
      item.seen = true
      this.confirmAddress = item.id
    },
    mouseLeave(item) {
      item.seen = false
      this.confirmAddress = 0
    },
	add() {
		this.addVisible=true
		this.form = JSON.parse(JSON.stringify(this.tempForm))
	},
    edit(item, index) {
		this.form = JSON.parse(JSON.stringify(item))
		this.addressID = this.form.id
		this.addressIndex = index
		this.editVisible = true
    },
    deleteDialog(val, index) {
      this.addressID = val
      this.addressIndex = index
      this.deleteVisible = true
    },
    postEdit() {
		this.$refs.form.validate(valid => {
			if (!valid) {
				return
			}
			let form = this.form
			form.province = form.areaArr[0]
			form.city = form.areaArr[1]
			form.area = form.areaArr[2]

			addressesAPI
			.postAddress(form)
			.then(res => {
				if (res.status_code === 200) {
					this.address.unshift(res.data)
					this.addVisible = false
					this.notifySucceed('新建收货地址成功')
				} else if (res.status_code === 20001) {
					//token过期，需要重新登录
					this.loginExpired(res.msg)
				} else {
					this.notifyError('新建收货地址失败', res.msg)
				}
			})
			.catch(err => {
				this.notifyError('新建收货地址失败', err)
			})
		})
    },
    saveEdit() {
		this.$refs.form.validate(valid => {
			if (!valid) {
				return
			}
			let form = this.form
			form.province = form.areaArr[0]
			form.city = form.areaArr[1]
			form.area = form.areaArr[2]
			addressesAPI
			.updateAddress(this.addressID, form)
			.then(res => {
				if (res.status_code === 200) {
					this.address[this.addressIndex] = this.form
					this.editVisible = false
					this.notifySucceed('修改收货地址成功')
				} else if (res.status_code === 20001) {
					//token过期，需要重新登录
					this.loginExpired(res.msg)
				} else {
					this.notifyError('修改收货地址失败', res.msg)
				}
			})
			.catch(err => {
				this.notifyError('修改收货地址失败', err)
			})
		})
    },
    deleteAddress() {
      addressesAPI
        .deleteAddress(this.addressID)
        .then(res => {
          if (res.status_code === 200) {
            this.address.splice(this.addressIndex, 1)
            this.deleteVisible = false
            this.notifySucceed('删除收货地址成功')
          } else if (res.status_code === 20001) {
            //token过期，需要重新登录
            this.loginExpired(res.msg)
          } else {
            this.notifyError('删除收货地址失败', res.msg)
          }
        })
        .catch(err => {
          this.notifyError('删除收货地址失败', err)
        })
    }
  },
  components: {
    CenterMenu
  }
}
</script>
<style scoped>
.address-layout {
  max-width: 1225px;
  margin: 0 auto;
}
.address-content {
  background-color: #ffffff;
  margin-bottom: 30px;
}
.address-title {
  height: 100px;
  display: flex;
  align-items: center;
}
.address-title p {
  font-size: 30px;
  color: #757575;
  margin-left: 50px;
}
.extra {
  height: 10px;
}
/*收货地址列表*/
.address .address-layout .address-content .address-body {
  overflow: hidden;
  width: 910px;
  margin: 0 auto;
}
.address .address-layout .address-content .address-body ul li {
  float: left;
  color: #333;
  width: 220px;
  height: 178px;
  border: 1px solid #e0e0e0;
  padding: 15px 24px 0;
  margin-right: 17px;
  margin-bottom: 24px;
}
.address .address-layout .address-content .address-body .in-section {
  border: 1px solid #ff6700;
}
.address .address-layout .address-content .address-body li h2 {
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
  margin-bottom: 10px;
}
.address .address-layout .address-content .address-body li p {
  font-size: 14px;
  color: #757575;
}
.address .address-layout .address-content .address-body li .address {
  padding: 10px 0;
  max-width: 180px;
  height: 70px;
  line-height: 22px;
  overflow: hidden;
}

.address .address-layout .address-content .address-body li .operate {
  color: #ff6700;
  font-size: 14px;
  float: right;
}

.address .address-layout .address-content .address-body li .operate span {
  margin-right: 10px;
}
.address .address-layout .address-content .address-body .add-address {
  text-align: center;
  line-height: 30px;
}
.address .address-layout .address-content .address-body .add-address i {
  font-size: 30px;
  padding-top: 50px;
  text-align: center;
}
/*收货地址列表END*/
.address .address-layout .delete-dialog {
  margin: 0 auto;
  width: 180px;
  font-size: 20px;
}
</style>